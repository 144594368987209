import React from "react";
import {
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TablePagination from "../../components/TablePagination/TablePagination";
import UserTableAvatar from "../../components/UserTableAvatar/UserTableAvatar";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import {
  evaluationStatusChip,
  font_13_icon,
  getStatus,
  scoreChip
} from "../../utils/utils";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  assessment_evaluation_play_default_icon,
  assessment_evaluation_play_disabled_icon,
  review_disabled_icon,
  review_icon,
} from "../../constants/icons";
import { AttendeeStatus, HelpTexts } from "../../constants/constants";
import { useSelector } from "react-redux";
const { DROPPED, SEPARATED } = AttendeeStatus;
const { NON_ACTIVE_ATTENDEE } = HelpTexts;
const tz = "Asia/Kolkata";

const AssessmentsTable = ({
  getAssessmentsData,
  totalPages,
  handlePageChange,
  currentPage,
  isAttendee,
  isMentor,
  isInterviewer,
  handleViewEvaluation,
  handlePlayEvaluation,
}) => {
  const attendeeStatuses = useSelector(
    (state) => state?.utility?.constants?.attendeeStatuses
  );
  return (
    <>
      <TableContainer className="table-container exercises-table-container">
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ minWidth: "100%" }}
        >
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              {!isAttendee ? (
                <TableCell
                  align="left"
                  sx={{ minWidth: "10rem" }}
                  className="padding-left-1"
                >
                  <Typography sx={{ minWidth: "10rem" }} variant="font_13_bold">
                    {isMentor ? `Attendee` : isInterviewer ? `Interviewee` : ``}
                  </Typography>
                </TableCell>
              ) : null}

              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography sx={{ minWidth: "10rem" }} variant="font_13_bold">
                  Competency
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography sx={{ minWidth: "10rem" }} variant="font_13_bold">
                  Bootcamp
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Module</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Assessment</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Type</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Status</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Start Date</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Lead Interviewer</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Co Interviewer</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Score</Typography>
              </TableCell>
              <TableCell sx={{ minWidth: "8rem" }}>
                <Typography variant="font_13_bold" className="padding-right-2">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getAssessmentsData?.data?.results?.map(
              (assessmentEvaluation, index) => {
                const assessment = assessmentEvaluation?.assessment;
                const module = assessment?.module;
                const attendee =
                  assessmentEvaluation?.moduleAttendee?.attendee?.attendee ||
                  assessmentEvaluation?.bootcampAttendee?.attendee;
                const bootcamp = module
                  ? assessmentEvaluation?.moduleAttendee?.attendee?.bootcamp
                  : assessment?.bootcamp;
                const status = evaluationStatusChip(
                  assessmentEvaluation?.status
                );
                const evaluationId = assessmentEvaluation?.id;
                const permissions = assessmentEvaluation?.permission;
                const {
                  canSubmitAssessmentEvaluation,
                  canViewSubmitAssessmentEvaluation,
                  submitAssessmentEvaluationMessage,
                  canViewOpenAssessmentEvaluation,
                  canOpenAssessmentEvaluation,
                  openAssessmentEvaluationMessage,
                } = permissions || {};
                const userDropped =
                  assessmentEvaluation?.bootcampAttendee !== null
                    ? assessmentEvaluation?.bootcampAttendee?.status === DROPPED
                    : assessmentEvaluation?.moduleAttendee?.attendee?.status ===
                        DROPPED ||
                      assessmentEvaluation?.moduleAttendee?.status === DROPPED;
                const userSeperated =
                  assessmentEvaluation?.bootcampAttendee !== null
                    ? assessmentEvaluation?.bootcampAttendee?.status ===
                      SEPARATED
                    : assessmentEvaluation?.moduleAttendee?.attendee?.status ===
                        SEPARATED ||
                      assessmentEvaluation?.moduleAttendee?.status ===
                        SEPARATED;

                const attendeeStatus =
                  assessmentEvaluation?.bootcampAttendee != null
                    ? assessmentEvaluation?.bootcampAttendee?.status ===
                        DROPPED ||
                      assessmentEvaluation?.bootcampAttendee?.status ===
                        SEPARATED
                      ? assessmentEvaluation?.bootcampAttendee?.status
                      : null
                    : assessmentEvaluation?.moduleAttendee?.status ===
                        DROPPED ||
                      assessmentEvaluation?.moduleAttendee?.status === SEPARATED
                    ? assessmentEvaluation?.moduleAttendee?.status
                    : assessmentEvaluation?.moduleAttendee?.attendee?.status ===
                        DROPPED ||
                      assessmentEvaluation?.moduleAttendee?.attendee?.status ===
                        SEPARATED
                    ? assessmentEvaluation?.moduleAttendee?.attendee?.status
                    : null;

                const statusUpdatedDate =
                  assessmentEvaluation?.bootcampAttendee != null
                    ? assessmentEvaluation?.bootcampAttendee?.status ===
                        DROPPED ||
                      assessmentEvaluation?.bootcampAttendee?.status ===
                        SEPARATED
                      ? assessmentEvaluation?.bootcampAttendee
                          ?.statusUpdatedDate
                      : null
                    : assessmentEvaluation?.moduleAttendee?.status ===
                        DROPPED ||
                      assessmentEvaluation?.moduleAttendee?.status === SEPARATED
                    ? assessmentEvaluation?.moduleAttendee?.statusUpdatedDate
                    : assessmentEvaluation?.moduleAttendee?.attendee?.status ===
                        DROPPED ||
                      assessmentEvaluation?.moduleAttendee?.attendee?.status ===
                        SEPARATED
                    ? assessmentEvaluation?.moduleAttendee?.attendee
                        ?.statusUpdatedDate
                    : null;
                const isActiveOrCompleted =
                  assessmentEvaluation?.status === "ACTIVE" ||
                  assessmentEvaluation?.status === "COMPLETED";
                const competencyName =
                  assessmentEvaluation?.moduleAttendee?.attendee?.attendee
                    ?.competency?.name ||
                  assessmentEvaluation?.bootcampAttendee?.attendee?.competency
                    ?.name;
                return (
                  <TableRow className="table-body-row" key={index}>
                    {!isAttendee ? (
                      <TableCell className="padding-left-1">
                        <UserTableAvatar
                          imgAlt={attendee?.fullName}
                          imgSrc={attendee?.profilePictureUrl}
                          href={attendee?.employeeCode}
                          label={attendee?.fullName}
                          subLabel={attendee?.email}
                          deleted={userDropped || userSeperated}
                          tooltip={
                            userDropped || userSeperated
                              ? NON_ACTIVE_ATTENDEE(
                                  getStatus(attendeeStatuses, attendeeStatus),
                                  dayjs(statusUpdatedDate)
                                    ?.tz(tz)
                                    ?.format("DD MMM YYYY")
                                )
                              : null
                          }
                        />
                      </TableCell>
                    ) : null}
                    <TableCell align="left" className="padding-left-1">
                      <Typography variant="font_12">
                        {competencyName}
                      </Typography>
                    </TableCell>
                    <TableCell className="padding-left-1">
                      <BootstrapTooltip title="View Bootcamp">
                        <Typography variant="font_12" color="secondary">
                          <Link
                            to={`/`}
                            state={{
                              propsToPass: {
                                bootcampName: bootcamp?.name,
                                bootcampId: bootcamp?.id,
                              },
                            }}
                          >
                            <u>{bootcamp?.name}</u>
                          </Link>
                        </Typography>
                      </BootstrapTooltip>
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <BootstrapTooltip title="View Module">
                        <Typography variant="font_12" color="secondary">
                          <Link
                            to={`/bootcamp/${bootcamp?.id}/module/${module?.id}`}
                          >
                            <u>{module?.name}</u>
                          </Link>
                        </Typography>
                      </BootstrapTooltip>
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      {!isAttendee ? (
                        <BootstrapTooltip title="View Assessment">
                          <Typography variant="font_12" color="secondary">
                            <Link
                              to={
                                module
                                  ? `/bootcamp/${bootcamp?.id}/module/${module?.id}/assessments`
                                  : `/bootcamp/${bootcamp?.id}/assessments`
                              }
                            >
                              <u>{assessment?.title}</u>
                            </Link>
                          </Typography>
                        </BootstrapTooltip>
                      ) : (
                        assessment?.title
                      )}
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <Typography variant="font_12">
                        {assessment?.type}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <Chip
                        className="same-size-chip"
                        label={status?.name}
                        variant={status?.theme}
                      />
                    </TableCell>
                    <TableCell className="padding-left-1">
                      <Typography variant="font_12">
                        {!!assessmentEvaluation?.startDate
                          ? dayjs(assessmentEvaluation?.startDate)?.format(
                              "DD/MM/YYYY hh:mm A"
                            )
                          : !!assessmentEvaluation?.assessment?.activeFromDate
                          ? dayjs(
                              assessmentEvaluation?.assessment?.activeFromDate
                            )?.format("DD/MM/YYYY hh:mm A")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <UserTableAvatar
                        imgAlt={assessmentEvaluation?.leadInterviewer?.fullName}
                        imgSrc={
                          assessmentEvaluation?.leadInterviewer
                            ?.profilePictureUrl
                        }
                        href={
                          assessmentEvaluation?.leadInterviewer?.employeeCode
                        }
                        label={assessmentEvaluation?.leadInterviewer?.fullName}
                        subLabel={assessmentEvaluation?.leadInterviewer?.email}
                        deleted={assessmentEvaluation?.leadInterviewer?.deleted}
                      />
                    </TableCell>
                    <TableCell>
                      <UserTableAvatar
                        imgAlt={assessmentEvaluation?.coInterviewer?.fullName}
                        imgSrc={
                          assessmentEvaluation?.coInterviewer?.profilePictureUrl
                        }
                        href={assessmentEvaluation?.coInterviewer?.employeeCode}
                        label={assessmentEvaluation?.coInterviewer?.fullName}
                        subLabel={assessmentEvaluation?.coInterviewer?.email}
                        deleted={assessmentEvaluation?.coInterviewer?.deleted}
                      />
                    </TableCell>
                    <TableCell className="padding-left-1">
                      <Chip
                        className="score-chip"
                        label={`${assessmentEvaluation?.marksObtained || 0}/${
                          assessmentEvaluation?.totalMarks}`}
                        variant={scoreChip(
                          assessmentEvaluation?.marksObtained,
                          assessmentEvaluation?.totalMarks,
                          null,
                          assessmentEvaluation?.status !== "COMPLETED"
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <Box>
                        {canViewOpenAssessmentEvaluation && (
                          <IconButtons
                            height={font_13_icon}
                            width={font_13_icon}
                            image={review_icon}
                            classList="margin-right-1"
                            handleClick={() =>
                              handleViewEvaluation(
                                bootcamp?.id,
                                module?.id,
                                assessment?.id,
                                evaluationId
                              )
                            }
                            tooltip="View Evaluation"
                            disabledImage={review_disabled_icon}
                            disabledTooltip={
                              !canOpenAssessmentEvaluation
                                ? openAssessmentEvaluationMessage
                                : ""
                            }
                            disabled={!canOpenAssessmentEvaluation}
                          />
                        )}
                        {canViewSubmitAssessmentEvaluation && (
                          <IconButtons
                            height={font_13_icon}
                            width={font_13_icon}
                            image={assessment_evaluation_play_default_icon}
                            classList="margin-right-1"
                            handleClick={() =>
                              handlePlayEvaluation(evaluationId)
                            }
                            tooltip="Play Evaluation"
                            disabledImage={
                              assessment_evaluation_play_disabled_icon
                            }
                            disabledTooltip={
                              !canSubmitAssessmentEvaluation
                                ? submitAssessmentEvaluationMessage
                                : ""
                            }
                            disabled={!canSubmitAssessmentEvaluation}
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className="table-pagination-container">
        <Typography variant="font_16">
          Total {getAssessmentsData?.data?.totalResults} Records
        </Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default AssessmentsTable;
