import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addQuestionApi,
  associateQuestionsApi,
  deleteQuestionApi,
  disassociateQuestionApi,
  getAssociateQuestionsApi,
  getQuestionListApi,
  getTopicViaIdApi,
  updateQuestionApi,
  addBulkQuestionsApi
} from "../services/questions";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  questionList: [],
  associateQuestionsList: [],
  topicData: [],
  loading: false,
  associateLoading: false,
  topicLoading: false,
  error: null,
};

export const getQuestionList = createAsyncThunk(
  "getQuestionList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getQuestionListApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "deleteQuestion",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const { questId, id, type, offset } = data;
      const callApi = await deleteQuestionApi(questId);
      if (callApi) {
        await dispatch(getQuestionList({ id, type, offset }));
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "addQuestion",
  async (data, { dispatch, rejectWithValue }) => {
    const { id, type, offset, exercise } = data;
    try {
      const callApi = await addQuestionApi({ id, type, exercise });
      if (callApi) {
        await dispatch(getQuestionList({ id, type, offset }));
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addBulkQuestions = createAsyncThunk(
  "addBulkQuestions",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      return await addBulkQuestionsApi(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editQuestion = createAsyncThunk(
  "editQuestion",
  async (data, { dispatch, rejectWithValue }) => {
    const { id, type, offset, quesId, exercise } = data;

    try {
      const callApi = await updateQuestionApi({
        questionId: quesId,
        exercise,
      });
      if (callApi) {
        await dispatch(getQuestionList({ id, type, offset }));
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssociateQuestions = createAsyncThunk(
  "getAssociateQuestions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssociateQuestionsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const associateQuestions = createAsyncThunk(
  "associateQuestions",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const callApi = await associateQuestionsApi(data);
      if (callApi) {
        await dispatch(
          getQuestionList({
            id: data?.sessionId,
            type: data?.type,
            offset: data.offset,
          })
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopicViaId = createAsyncThunk(
  "getTopicViaId",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getTopicViaIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const disassociateQuestion = createAsyncThunk(
  "disassociateQuestion",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const callApi = await disassociateQuestionApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const questionList = createSlice({
  name: "questionList",
  initialState,
  reducers: {
    clearQuestionList(state) {
      state.questionList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuestionList.fulfilled, (state, action) => {
        state.loading = false;
        state.questionList = action.payload;
      })
      .addCase(getQuestionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            message="Question Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            message="Question Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addBulkQuestions.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBulkQuestions.fulfilled, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            message="Question Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(addBulkQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(editQuestion.fulfilled, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            message="Question Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(editQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAssociateQuestions.pending, (state) => {
        state.associateLoading = true;
      })
      .addCase(getAssociateQuestions.fulfilled, (state, action) => {
        state.associateLoading = false;
        state.associateQuestionsList = action.payload;
      })
      .addCase(getAssociateQuestions.rejected, (state, action) => {
        state.associateLoading = false;
        state.error = action.payload;
      })
      .addCase(associateQuestions.pending, (state) => {
        state.associateLoading = true;
      })
      .addCase(associateQuestions.fulfilled, (state, action) => {
        state.associateLoading = false;
        toast.custom(
          <CustomToaster
            message="Questions Associated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(associateQuestions.rejected, (state, action) => {
        state.associateLoading = false;
        state.error = action.payload;
      })
      .addCase(getTopicViaId.pending, (state) => {
        state.topicLoading = true;
      })
      .addCase(getTopicViaId.fulfilled, (state, action) => {
        state.topicLoading = false;
        state.topicData = action.payload;
      })
      .addCase(getTopicViaId.rejected, (state, action) => {
        state.topicLoading = false;
        state.error = action.payload;
      })
      .addCase(disassociateQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(disassociateQuestion.fulfilled, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            message="Question Disassociated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(disassociateQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearQuestionList } = questionList.actions;
export default questionList.reducer;
