import React, { useEffect, useRef, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { toast } from "react-hot-toast";
import CustomToaster from "../../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../../constants/constants";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

const QuestionSidebar = ({
  questions,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  isAnswered,
  responses,
}) => {
  const questionRefs = useRef([]);

  const handleTileClick = (index) => {
    if (isAnswered) {
      toast.custom(
        <CustomToaster
          message="Please save the answer before navigating to other question."
          title={TOAST_TITLE.ACTION}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.INFO}
        />
      );
    } else {
      setCurrentQuestionIndex(index);
    }
  };

  useEffect(() => {
    if (questionRefs.current[currentQuestionIndex]) {
      questionRefs.current[currentQuestionIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [currentQuestionIndex]);

  return (
    <Box className="question-tiles-sidebar">
      <List component="nav" className="questions-list">
        {questions?.map((question, index) => {
          const isAttempted = responses?.some(
            (attempted) =>
              !attempted?.removeAnswer && attempted?.questionId === question?.id
          );
          return (
            <ListItem
              key={question?.id}
              component="button"
              className={`question-tiles ${
                index === currentQuestionIndex ? "selected-question-tile" : ""
              } ${isAttempted ? "attempted-question-tile" : ""}`}
              sx={{
                backgroundColor:
                  index === currentQuestionIndex
                    ? "#F00082"
                    : question?.isSaved || isAttempted
                    ? "#3087E9"
                    : "transparent",
              }}
              onClick={() => handleTileClick(index)}
              ref={(el) => (questionRefs.current[index] = el)}
            >
              <ListItemText
                className="question-numbers"
                sx={{
                  color:
                    index === currentQuestionIndex ||
                    question?.isSaved ||
                    isAttempted
                      ? "#fff"
                      : "#f00082",
                }}
                primary={`${index + 1}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

const Question = ({
  question,
  currentQuestionIndex,
  totalQuestions,
  saveResponse,
  removeAnswer,
  questions,
  setCurrentQuestionIndex,
  responses,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]); // For multi select
  const [isAnswered, setIsAnswered] = useState(false);
  const isLastQuestion = currentQuestionIndex + 1 === totalQuestions;

  useEffect(() => {
    const savedResponse = responses?.find(
      (response) => response?.questionId === question?.id
    );
    if (question?.question?.type === "SINGLE_SELECT") {
      setSelectedOption(savedResponse?.optionIds[0] || 0);
    } else if (question?.question?.type === "MULTI_SELECT") {
      setSelectedOptions(savedResponse?.optionIds || []);
    }
    setIsAnswered(false);
  }, [question?.id, responses]);

  const handleOptionChange = (event) => {
    const newOption = +event.target.value;
    setIsAnswered(hasChanges(newOption));
    setSelectedOption(newOption, null);
  };
  const handleCheckboxChange = (event, optionId) => {
    let newSelectedOptions = [];
    if (event.target.checked) {
      newSelectedOptions = [...selectedOptions, optionId];
    } else {
      newSelectedOptions = selectedOptions.filter((id) => id !== optionId);
    }
    setIsAnswered(hasChanges(null, newSelectedOptions));
    setSelectedOptions(newSelectedOptions);
  };

  const handleSaveContinue = (event) => {
    const hasChangesFlag = hasChanges();

    if (hasChangesFlag) {
      if (question?.question?.type === "SINGLE_SELECT") {
        saveResponse(question?.id, selectedOption);
      } else {
        saveResponse(question?.id, selectedOptions);
      }
    }

    setIsAnswered(false);
    if (!isLastQuestion) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const hasChanges = (newSingleSelectOption, newMultiSelectOptions) => {
    let hasChanges = false;
    const savedResponse = responses?.find(
      (response) => response?.questionId === question?.id
    );
    if (question?.question?.type === "SINGLE_SELECT") {
      const previousSelection = savedResponse?.optionIds?.[0] || 0;
      const selected =
        newSingleSelectOption != null ? newSingleSelectOption : selectedOption;
      if (selected !== previousSelection) {
        hasChanges = true;
      }
    } else if (question?.question?.type === "MULTI_SELECT") {
      const previousSelections = savedResponse?.optionIds || [];
      const selected =
        newMultiSelectOptions != null ? newMultiSelectOptions : selectedOptions;
      const areSelectionsDifferent =
        selected?.length !== previousSelections?.length ||
        selected.sort().join(",") !== previousSelections.sort().join(",");

      if (areSelectionsDifferent) {
        hasChanges = true;
      }
    }
    return hasChanges;
  };

  const handleRemoveAnswer = (questionId) => {
    setSelectedOption(0);
    setSelectedOptions([]);
    setIsAnswered(false);
    if (hasChanges(0, [])) {
      removeAnswer(questionId);
    }
  };

  const handlePrevious = () => {
    if (isAnswered) {
      toast.custom(
        <CustomToaster
          message="Please save the answer before navigating to other question."
          title={TOAST_TITLE.ACTION}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.INFO}
        />
      );
    } else if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  return (
    <>
      <Box className="mcq-details">
        <QuestionSidebar
          questions={questions}
          currentQuestionIndex={currentQuestionIndex}
          setCurrentQuestionIndex={setCurrentQuestionIndex}
          isAnswered={isAnswered}
          responses={responses}
        />
        <Box className="question-section" sx={{ padding: "20px", flex: 1 }}>
          <Box className="question-title">
            <Typography variant="font_13_bold">
              {question?.question?.title}
            </Typography>
          </Box>
          <Box className="question-description">
            <Typography variant="font_12" className="grey-text">
              Description
            </Typography>
            <Box>
              <Typography variant="font_13">
                {question?.question?.description}
              </Typography>
            </Box>
          </Box>
          {question?.question?.type === "SINGLE_SELECT" ? (
            // Single-select using RadioGroup
            <RadioGroup
              className="mcq-options"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              {question?.options?.map((option) => (
                <FormControlLabel
                  className={`options single-select-options ${
                    selectedOption === option?.id ? "selected-option" : ""
                  }`}
                  key={option?.id}
                  value={option?.id}
                  control={<Radio sx={{ display: "none" }} />}
                  label={option?.optionText}
                />
              ))}
            </RadioGroup>
          ) : (
            // Multi-select using Checkboxes
            <Box className="mcq-options">
              {question?.options?.map((option) => (
                <FormControlLabel
                  key={option?.id}
                  className={`options multi-select-options ${
                    selectedOptions.includes(option?.id)
                      ? "selected-option"
                      : ""
                  }`}
                  control={
                    <Checkbox
                      sx={{ display: "none" }}
                      checked={selectedOptions.includes(option?.id)}
                      onChange={(event) =>
                        handleCheckboxChange(event, option?.id)
                      }
                    />
                  }
                  label={option?.optionText}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <Box
        className="mcq-action"
        sx={{
          justifyContent:
            currentQuestionIndex + 1 > 1 ? "space-between" : "right",
        }}
      >
        {currentQuestionIndex + 1 > 1 && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handlePrevious}
            className="block-button"
            sx={{ marginRight: "1.875rem", maxWidth: "12.5rem" }}
          >
            <Typography variant="font_12_bold_700">Previous</Typography>
          </Button>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleRemoveAnswer(question?.id)}
            className="block-button"
            sx={{ marginRight: "1.875rem", minWidth: "12.5rem" }}
            disabled={
              question?.question?.type === "SINGLE_SELECT"
                ? !selectedOption
                : !selectedOptions?.length
            }
          >
            <Typography variant="font_12_bold_700">Remove Answer</Typography>
          </Button>
          {currentQuestionIndex + 1 <= totalQuestions ? (
            <Button
              onClick={(event) => handleSaveContinue(event)}
              variant="contained"
              color="secondary"
              className="block-button"
              sx={{ minWidth: "12.5rem" }}
              disabled={!isAnswered}
            >
              <Typography variant="font_12_bold_700">
                {isLastQuestion ? `Save` : `Save & Continue`}
              </Typography>
            </Button>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default Question;
