import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import ReactQuill from "react-quill";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import Loader from "../../components/Loader/Loader";
import {
  checkDateTimeStatus,
  getLatestDate,
  hasDetailsChanged,
  getMaxDate,
  getMinDate,
  regex,
  shouldDisableWeekends,
} from "../../utils/utils";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import CustomToolbar from "../../components/CustomToolbar/CustomToolbar";
import dayjs from "dayjs";

const compareFormFields = (initial, current) => {
  const fieldsToCheck = [
    "moduleId",
    "name",
    "agenda",
    "topicId",
    "startDate",
    "duration",
    "containsExercise",
    "exerciseDueDate",
    "maxExerciseScore",
  ];

  return fieldsToCheck.some((field) => {
    if (field === "startDate" || field === "exerciseDueDate") {
      return !dayjs(initial[field]).isSame(dayjs(current[field]));
    } else if (field === "name") {
      return initial[field]?.trim() !== current[field]?.trim();
    }
    return initial[field] !== current[field];
  });
};

const SessionCreate = ({
  open,
  onClose,
  sessionDetails,
  handleSessionDetailsChange,
  allTopicOptions,
  handleSubmitSession,
  isEditSession,
  loader,
  errors,
  module,
  apiError,
  isSessionSpoc,
  sessionSpecialChar,
  sessionLengthError,
  numberInputError1,
  numberInputError2,
}) => {
  const sessionIsOngoing =
    checkDateTimeStatus(sessionDetails?.startDate, sessionDetails?.endDate) ===
    "ongoing";
  const sessionIsConcluded =
    checkDateTimeStatus(sessionDetails?.startDate, sessionDetails?.endDate) ===
    "past";

  const sessionIsScheduled =
    checkDateTimeStatus(sessionDetails?.startDate, sessionDetails?.endDate) ===
    "future";

  const disableInputNotSpoc = isEditSession
    ? !(sessionIsScheduled && isSessionSpoc)
    : false;

  const disableDuration = isEditSession
    ? !((sessionIsScheduled || sessionIsOngoing) && isSessionSpoc)
    : false;

  const [initialDisabledState, setInitialDisabledState] = useState(false);

  useEffect(() => {
    const initialDisabled =
      isEditSession &&
      ((sessionIsOngoing && sessionDetails?.containsExercise) ||
        sessionIsConcluded);
    setInitialDisabledState(initialDisabled);
  }, [isEditSession, sessionIsOngoing, sessionIsConcluded]);

  const [initialSessionDetails, setInitialSessionDetails] =
    useState(sessionDetails);

  useEffect(() => {
    setInitialSessionDetails(sessionDetails);
  }, [sessionDetails?.sessionId]);

  const sessionDetailsChanged = compareFormFields(
    initialSessionDetails,
    sessionDetails
  );

  return (
    <>
      {loader && <Loader />}

      <Dialog
        className="modal-drawer-container"
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "1rem",
            zIndex: 1300,
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {isEditSession ? `Edit Session` : `Create Session`}
        </DialogTitle>
        <DialogContent
          className="dialog-content"
          sx={{ marginBottom: "0 !important" }}
        >
          <Box className="width-100">
            <Box sx={{ marginBottom: "0.75rem" }}>
              <SimpleAutoComplete
                label="Module"
                placeholder="Select Module"
                options={sessionDetails?.module}
                onChange={(event, newValue) =>
                  handleSessionDetailsChange("moduleId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                value={module}
                error={!!errors?.moduleId || apiError}
                helperText={errors?.moduleId}
                disabled={true}
                required={true}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === module?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
            </Box>

            <Box sx={{ marginBottom: "0.75rem" }}>
              <TextField
                autoFocus
                size="small"
                label="Name"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={sessionDetails?.name}
                onChange={(e) =>
                  handleSessionDetailsChange("name", e?.target?.value)
                }
                error={
                  !!errors?.name ||
                  apiError ||
                  sessionSpecialChar ||
                  sessionLengthError
                }
                helperText={
                  sessionSpecialChar
                    ? "Should not contain special Characters"
                    : sessionLengthError
                    ? "Maximum 50 characters allowed"
                    : errors?.name
                }
                required={true}
                disabled={isEditSession && sessionIsConcluded}
              />
            </Box>

            <Box sx={{ marginBottom: "0.75rem" }}>
              <SimpleAutoComplete
                label="Topic"
                placeholder="Select Topic"
                options={
                  isEditSession ? sessionDetails?.topic : allTopicOptions
                }
                onChange={(event, newValue) =>
                  handleSessionDetailsChange("topicId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                value={sessionDetails?.topicId}
                disabled={isEditSession}
                error={!!errors?.topicId || apiError}
                helperText={errors?.topicId}
                required={true}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === sessionDetails?.topicId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
            </Box>

            <Box sx={{ marginBottom: "0.75rem" }}>
              <Box sx={{ marginBottom: "0.75rem" }}>
                <Typography
                  color={!!errors.agenda || apiError ? "error" : "offline"}
                  variant="font_14"
                  sx={{ marginBottom: "1rem" }}
                >
                  Agenda *
                </Typography>
              </Box>
              <CustomToolbar />
              <ReactQuill
                className="session-quill"
                theme="snow"
                value={sessionDetails?.agenda}
                onChange={(value) =>
                  handleSessionDetailsChange("agenda", value)
                }
                readOnly={isEditSession && sessionIsConcluded}
                modules={{
                  toolbar: {
                    container: "#toolbar",
                  },
                }}
                placeholder="Enter Text"
                bounds={".session-quill"}
              />
              {!!errors.agenda || apiError ? (
                <Typography
                  sx={{ paddingLeft: "1rem", marginBottom: "1rem" }}
                  variant="font_12"
                  color="error"
                >
                  {errors.agenda}
                </Typography>
              ) : null}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                marginBottom: "0.75rem",
              }}
            >
              <DateTimePickerComponent
                label="Start Date"
                format="DD/MM/YYYY hh:mm A"
                onChange={(e) => handleSessionDetailsChange("startDate", e)}
                value={sessionDetails?.startDate}
                views={["year", "day", "hours", "minutes"]}
                error={!!errors?.startDate || apiError}
                helperText={errors?.startDate}
                classList="margin-right-2"
                required={true}
                minDateTime={getMaxDate([module?.startDate, dayjs()])}
                maxDateTime={getMinDate([module?.endDate])}
                disabled={disableInputNotSpoc}
                shouldDisableDate={shouldDisableWeekends}
                closeOnSelect={false}
                disablePast={true}
              />

              <TextField
                size="small"
                label="Duration (in hours)"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={sessionDetails?.duration}
                onChange={(e) =>
                  handleSessionDetailsChange("duration", e?.target?.value)
                }
                error={!!errors?.duration || apiError || numberInputError1}
                helperText={
                  numberInputError1
                    ? "Should only contain number"
                    : errors?.duration
                }
                required={true}
                disabled={disableDuration}
              />
            </Box>
            <hr />
            <Box className="date-container">
              <Checkbox
                checked={sessionDetails?.containsExercise}
                size="small"
                color="secondary"
                className="exerciseCheck"
                onChange={(e) => {
                  handleSessionDetailsChange(
                    "containsExercise",
                    e?.currentTarget?.checked
                  );
                }}
                required={true}
                disabled={initialDisabledState}
              />
              <Typography variant="font_14">Contains Exercise</Typography>
            </Box>

            {sessionDetails?.containsExercise && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginBottom: "0.75rem",
                }}
              >
                <DateTimePickerComponent
                  label="Exercise Due Date"
                  format="DD/MM/YYYY hh:mm A"
                  onChange={(e) =>
                    handleSessionDetailsChange("exerciseDueDate", e)
                  }
                  value={
                    sessionDetails?.startDate
                      ? sessionDetails?.exerciseDueDate
                      : null
                  }
                  views={["year", "day", "hours", "minutes"]}
                  classList="margin-right-2"
                  error={!!errors?.exerciseDueDate || apiError}
                  helperText={errors?.exerciseDueDate}
                  disablePast={true}
                  minDateTime={getMaxDate([
                    module?.startDate,
                    sessionDetails?.startDate,
                    dayjs(),
                  ])}
                  maxDateTime={getMinDate([module?.endDate])}
                  disabled={
                    !sessionDetails?.startDate ||
                    (isEditSession && sessionIsConcluded)
                  }
                  required={true}
                  closeOnSelect={false}
                  minutesStep={1}
                />

                <TextField
                  size="small"
                  label="Max Exercise Score"
                  color="secondary"
                  fullWidth
                  className="filter-inputs"
                  margin="dense"
                  variant="outlined"
                  value={sessionDetails?.maxExerciseScore}
                  onChange={(e) =>
                    handleSessionDetailsChange(
                      "maxExerciseScore",
                      e?.target?.value
                    )
                  }
                  error={
                    !!errors?.maxExerciseScore || apiError || numberInputError2
                  }
                  helperText={
                    numberInputError2
                      ? "Should only contain number"
                      : errors?.maxExerciseScore
                  }
                  disabled={isEditSession && sessionIsConcluded}
                  required={true}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            className="block-button"
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmitSession}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={
              !sessionDetailsChanged || (isEditSession && sessionIsConcluded)
            }
          >
            <Typography variant="font_12_bold_700">
              {isEditSession ? `Update` : `Save`}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SessionCreate;
