import { Box, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { scoreChip } from "../../utils/utils";

const ViewAssessmentEvaluationPanel = ({
  assessmentInfo,
  openedByAttendee,
}) => {
  const assessment = assessmentInfo?.assessment;
  const attendee =
    assessmentInfo?.bootcampAttendee?.attendee ||
    assessmentInfo?.moduleAttendee?.attendee?.attendee;
  const leadInterviewer = assessmentInfo?.leadInterviewer?.fullName;
  const coInterviewer = assessmentInfo?.coInterviewer?.fullName;
  const percentage = assessmentInfo?.percentage || 0;
  const marksObtained = assessmentInfo?.marksObtained;
  const totalMarks = assessmentInfo?.totalMarks || 0;
  const isMCQ = assessment?.type === "MCQ";
  const isActiveOrCompleted =
    assessmentInfo?.status === "ACTIVE" ||
    assessmentInfo?.status === "COMPLETED";

  /////////////////////////////////////////////////////////////

  return (
    <Box className="margin-bottom-5">
      <Grid container className="assessment-panel-container">
        <Grid item md={12} className="assessment-panel-info">
          <Grid container item md={6} className="assessment-left-rows">
            <Grid item md={4}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Assessment Title
                </Typography>
              </Box>
            </Grid>
            <Grid item md={8} className="assessment-panel-info-box2">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12">{assessment?.title}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={6} className="assessment-right-rows">
            <Grid item md={4}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Attendee Name
                </Typography>
              </Box>
            </Grid>
            <Grid item md={8}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12">{attendee?.fullName}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className="assessment-panel-info">
          <Grid container item md={6} className="assessment-left-rows">
            <Grid item md={4}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Score
                </Typography>
              </Box>
            </Grid>
            <Grid item md={8} className="assessment-panel-info-box6">
              <Box className="assessment-panel-info-box">
                {(!openedByAttendee ||
                  (openedByAttendee &&
                    assessmentInfo?.status === "COMPLETED")) && (
                  <Typography variant="font_12">
                    <Chip
                      className="score-chip"
                      label={`${marksObtained || 0}/${totalMarks}`}
                      variant={
                        scoreChip(marksObtained, totalMarks, null, !isActiveOrCompleted)
                      }
                    />{" "}
                    ({percentage}%)
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={6} className="assessment-right-rows">
            <Grid item md={4}>
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12" className="grey-text">
                  Duration
                </Typography>
              </Box>
            </Grid>
            <Grid item md={8} className="assessment-panel-info-box6">
              <Box className="assessment-panel-info-box">
                <Typography variant="font_12">
                  {assessment?.durationInMinutes}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} className="assessment-panel-info">
          <Grid item md={2}>
            <Box className="assessment-panel-info-box">
              <Typography variant="font_12" className="grey-text">
                Instructions
              </Typography>
            </Box>
          </Grid>
          <Grid item md={10} className="assessment-panel-info-box6">
            <Box className="assessment-panel-info-box">
              {assessment?.instruction ? (
                <Box className="assessment-insturctions-details">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: assessment?.instruction,
                    }}
                  />
                </Box>
              ) : (
                "-"
              )}
            </Box>
          </Grid>
        </Grid>
        {!isMCQ && (
          <>
            <Grid item md={12} className="assessment-panel-info">
              <Grid item md={2}>
                <Box className="assessment-panel-info-box">
                  <Typography variant="font_12" className="grey-text">
                    Interviewers (Lead, Co)
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={10} className="assessment-panel-info-box6">
                <Box className="assessment-panel-info-box">
                  <Typography variant="font_12">
                    {leadInterviewer && coInterviewer
                      ? `${leadInterviewer}, ${coInterviewer}`
                      : leadInterviewer
                      ? `${leadInterviewer}`
                      : coInterviewer
                      ? `${coInterviewer}`
                      : ``}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item md={12} className="assessment-panel-info">
              <Grid item md={2}>
                <Box className="assessment-panel-info-box">
                  <Typography variant="font_12" className="grey-text">
                    Overall Feedback
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={10} className="assessment-panel-info-box6">
                <Box className="assessment-panel-info-box">
                  <Typography variant="font_12">
                    {assessmentInfo?.interviewerFeedback}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ViewAssessmentEvaluationPanel;
