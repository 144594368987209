import React, { useEffect, useState } from "react";
import "./Attendees.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import { useParams } from "react-router";
import Loader from "../../components/Loader/Loader";
import {
  filterOptionsWithSelectAllDesignations,
  sortByFullName,
} from "../../utils/utils";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

const compareFormFields = (initial, current) => {
  const fieldsToCheck = [
    "mentorCompetencyId",
    "mentorDesignationId",
    "leadMentorId",
    "coMentorCompetencyId",
    "coMentorDesignationId",
    "coMentorId",
    "batchId",
  ];

  return fieldsToCheck.some((field) => {
    if (field.includes("Id")) {
      return initial[field]?.id !== current[field]?.id;
    }
    return initial[field] !== current[field];
  });
};

const EditTrainee = ({
  open,
  title,
  handleClose,
  handleSubmit,
  editTraineeDetails,
  handleEditTraineeChange,
  errors,
  batchesList,
  competencyList,
  userLoading,
  isEditTrainee,
  isModuleActive,
  batchDetails,
  setBatchDetails,
}) => {
  /////////////////////// To disable update button if data isn't changed ////////////////////////
  const [initialEditTraineeDetails, setInitialEditTraineeDetails] =
    useState(editTraineeDetails);
  const [hasSetInitialDetails, setHasSetInitialDetails] = useState(false);

  useEffect(() => {
    if (
      !hasSetInitialDetails &&
      editTraineeDetails?.mentorCompetencyId &&
      editTraineeDetails?.coMentorCompetencyId
    ) {
      setInitialEditTraineeDetails(editTraineeDetails);
      setHasSetInitialDetails(true);
    }
  }, [
    editTraineeDetails?.mentorCompetencyId,
    editTraineeDetails?.coMentorCompetencyId,
    hasSetInitialDetails,
  ]);

  const editTraineeDetailsChanged = compareFormFields(
    initialEditTraineeDetails,
    editTraineeDetails
  );

  const handleCloseEditTrainee = () => {
    setHasSetInitialDetails(false);
    setBatchDetails(null);
    handleClose();
  };
  const handleSubmitEditTrainee = () => {
    setHasSetInitialDetails(false);
    if (
      batchDetails?.countOfAttendees?.total === 1 &&
      batchDetails?.name !== editTraineeDetails?.batchId?.name
    ) {
      setBatchDeleteModalOpen(true);
    } else {
      handleSubmit();
    }
  };

  //////////////////////// batch delete if last attendee Modal ///////////////////////
  const [batchDeleteModalOpen, setBatchDeleteModalOpen] = useState(false);
  const handleBatchDeleteModalClose = () => {
    setBatchDeleteModalOpen(false);
  };
  const handleBatchDelete = async () => {
    handleSubmit();
    setBatchDeleteModalOpen(false);
    setBatchDetails(null);
  };

  //////////////////////////////////////////////

  const params = useParams();
  const mentorWithAllDesignation = [].concat(
    ...(editTraineeDetails?.mentorCompetencyId?.designations?.results
      ?.filter((it) => !!it)
      ?.map((it) => it?.users?.results)
      ?.filter((it) => !!it) || [])
  );
  const filterMentorOptions = editTraineeDetails?.mentorDesignationId
    ? editTraineeDetails?.mentorDesignationId?.users?.results
    : mentorWithAllDesignation;

  const mentorOptions = sortByFullName(filterMentorOptions);

  const coMentorWithAllDesignation = [].concat(
    ...(editTraineeDetails?.coMentorCompetencyId?.designations?.results
      ?.filter((it) => !!it)
      ?.map((it) => it?.users?.results)
      ?.filter((it) => !!it) || [])
  );
  const filterCoMentorOptions = editTraineeDetails?.coMentorDesignationId
    ? editTraineeDetails?.coMentorDesignationId?.users?.results
    : coMentorWithAllDesignation;

  const coMentorOptions = sortByFullName(filterCoMentorOptions);

  return (
    <>
      {userLoading && <Loader />}
      <Dialog
        open={open}
        onClose={handleCloseEditTrainee}
        className="modal-drawer-container"
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            width: "27.125rem",
            zIndex: 1300,
            borderRadius: "1rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="dialog-title">{title}</DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            <Box
              component="fieldset"
              sx={{
                padding: "0 0.5rem 0.5rem",
                marginBottom: "0.5rem",
                border: "1px solid #c4c4c4",
                borderRadius: "0.4rem",
              }}
            >
              <legend>
                <Typography sx={{ padding: "0 0.2rem" }}>Mentor</Typography>
              </legend>

              <SimpleAutoComplete
                label="Competency"
                placeholder="Select Competency"
                options={competencyList}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("mentorCompetencyId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                value={editTraineeDetails?.mentorCompetencyId}
                disableClearable={isEditTrainee ? true : false}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id ===
                        editTraineeDetails?.mentorCompetencyId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              <SimpleAutoComplete
                label="Designation"
                placeholder="Select Designation"
                options={
                  editTraineeDetails?.mentorCompetencyId?.designations?.results
                }
                onChange={(event, newValue) =>
                  handleEditTraineeChange("mentorDesignationId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                filterOptions={(options, params) =>
                  filterOptionsWithSelectAllDesignations(
                    options,
                    params,
                    editTraineeDetails?.mentorCompetencyId?.designations
                      ?.results?.length,
                    mentorWithAllDesignation
                  )
                }
                value={
                  editTraineeDetails?.mentorDesignationId
                    ? editTraineeDetails?.mentorDesignationId
                    : { name: "All", all: true }
                }
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        (option?.name === "All" &&
                          option?.name === value?.inputValue) ||
                        option?.id ===
                          editTraineeDetails?.mentorDesignationId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
                disableClearable={true}
              />
              <SimpleAutoComplete
                label="Mentor"
                placeholder="Select Mentor"
                options={mentorOptions?.filter(
                  (user) => user?.id !== editTraineeDetails?.coMentorId?.id
                )}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) =>
                    `${option.fullName} ${option.email}`
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  );
                }}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("leadMentorId", newValue)
                }
                getOptionLabel={(option) => option?.fullName}
                value={editTraineeDetails?.leadMentorId}
                error={!!errors?.leadMentorId}
                helperText={errors?.leadMentorId}
                required={!params?.moduleId}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === editTraineeDetails?.leadMentorId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.fullName}`}</Box>
                        <Typography variant="caption" className="filter-title ">
                          {`${option?.email}`}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
                disableClearable={!params?.moduleId}
              />
            </Box>
          </Box>
          <Box
            component="fieldset"
            sx={{
              padding: "0 0.5rem 0.5rem",
              marginBottom: "0.5rem",
              border: "1px solid #c4c4c4",
              borderRadius: "0.4rem",
            }}
          >
            <legend>
              <Typography sx={{ padding: "0 0.2rem" }}>Co-Mentor</Typography>
            </legend>
            <Box mb={1} className="width-100">
              <SimpleAutoComplete
                label="Competency"
                placeholder="Select Competency"
                options={competencyList}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("coMentorCompetencyId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                value={editTraineeDetails?.coMentorCompetencyId}
                disableClearable={isEditTrainee ? true : false}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id ===
                        editTraineeDetails?.coMentorCompetencyId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              <SimpleAutoComplete
                label="Designation"
                placeholder="Select Designation"
                options={
                  editTraineeDetails?.coMentorCompetencyId?.designations
                    ?.results
                }
                onChange={(event, newValue) =>
                  handleEditTraineeChange("coMentorDesignationId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                filterOptions={(options, params) =>
                  filterOptionsWithSelectAllDesignations(
                    options,
                    params,
                    editTraineeDetails?.coMentorCompetencyId?.designations
                      ?.results?.length,
                    coMentorWithAllDesignation
                  )
                }
                value={
                  editTraineeDetails?.coMentorDesignationId
                    ? editTraineeDetails?.coMentorDesignationId
                    : { name: "All", all: true }
                }
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        (option?.name === "All" &&
                          option?.name === value?.inputValue) ||
                        option?.id ===
                          editTraineeDetails?.coMentorDesignationId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
                disableClearable={true}
              />
              <SimpleAutoComplete
                label="Co-Mentor"
                placeholder="Select Co-Mentor"
                options={coMentorOptions?.filter(
                  (user) => user?.id !== editTraineeDetails?.leadMentorId?.id
                )}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) =>
                    `${option.fullName} ${option.email}`
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  );
                }}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("coMentorId", newValue)
                }
                getOptionLabel={(option) => option?.fullName}
                value={editTraineeDetails?.coMentorId}
                error={!!errors?.coMentorId}
                helperText={errors?.coMentorId}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === editTraineeDetails?.coMentorId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.fullName}`}</Box>
                        <Typography variant="caption" className="filter-title ">
                          {`${option?.email}`}
                        </Typography>
                      </Box>
                    </li>
                  );
                }}
              />
            </Box>
          </Box>
          {params?.moduleId && (
            <Box mb={1} className="width-100">
              <SimpleAutoComplete
                label="Batch"
                placeholder="Select Batch"
                options={batchesList}
                onChange={(event, newValue) =>
                  handleEditTraineeChange("batchId", newValue)
                }
                getOptionLabel={(option) => option?.name}
                value={editTraineeDetails?.batchId}
                error={!!errors?.batchId}
                helperText={errors?.batchId}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === editTraineeDetails?.batchId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
                disableClearable={isModuleActive ? true : false}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "0 2rem 1.875rem" }}>
          <Button
            onClick={handleCloseEditTrainee}
            variant="outlined"
            color="secondary"
            className="add-role-btn"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmitEditTrainee}
            variant="contained"
            color="secondary"
            className="add-role-btn"
            disabled={!editTraineeDetailsChanged}
          >
            <Typography variant="outlineBtnLabel">Update</Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteModal
        open={batchDeleteModalOpen}
        title="Removing the last attendee will also delete the batch"
        handleClose={handleBatchDeleteModalClose}
        handleDelete={handleBatchDelete}
      />
    </>
  );
};

export default EditTrainee;
