import React, { useEffect } from "react";
import "./topicRelatedQuestionsListCard.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { font_13, scoreChip } from "../../utils/utils";
import { down_arrow_icon, up_arrow_icon } from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";
import { HelpTexts } from "../../constants/constants";

const { INTERVIEW } = HelpTexts;

const TopicRelatedQuestionsListCard = ({
  question,
  assessment,
  expandedId,
  setExpandedId,
  topicId,
  openedByAttendee,
  assessmentEvaluationStatus,
}) => {
  const isExpanded = expandedId === question?.id;
  const handleAccordionChange = () => {
    setExpandedId(isExpanded ? null : question?.id);
  };

  useEffect(() => {
    if (isExpanded) {
      setExpandedId(null);
    }
  }, [topicId]);

  const { markPerEasyQuestion, markPerMediumQuestion, markPerHardQuestion } =
    assessment || {};

  const questionType = question?.question?.difficultyLevel;
  const questionMarksObtained = question?.marksObtained;
  const questionTotalMarks =
    questionType === "EASY"
      ? markPerEasyQuestion
      : questionType === "MEDIUM"
      ? markPerMediumQuestion
      : questionType === "HARD"
      ? markPerHardQuestion
      : null;
  const isTypeInterview = assessment?.type === INTERVIEW;
  const options = question?.options;

  return (
    <Accordion
      className="accordion"
      expanded={isExpanded}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        className="accordion-summary"
        aria-controls="panel1-content"
        id={`panel-header-${question?.id}`}
      >
        <Box className="topic-question-head-container">
          <Box className="topic-question-head-left margin-right-2">
            <Typography variant="font_14">
              {question?.question?.title}
            </Typography>
          </Box>
          <Box className="topic-question-head-right">
            <Typography variant="font_12" className="margin-right-1">
              {(!openedByAttendee ||
                (openedByAttendee &&
                  assessmentEvaluationStatus === "COMPLETED")) && (
                <Chip
                  className="score-chip"
                  label={`${questionMarksObtained || 0}/${questionTotalMarks}`}
                  variant={scoreChip(
                    questionMarksObtained,
                    questionTotalMarks
                  )}
                />
              )}
            </Typography>
            <IconButtons
              height={font_13}
              width={font_13}
              image={isExpanded ? up_arrow_icon : down_arrow_icon}
            />
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails className="accordion-details">
        <Box>
          <Typography variant="font_12">
            <Typography variant="font_12_bold">Description:</Typography>{" "}
            {question?.question?.description}
            <br />
            {!isTypeInterview && (
              <>
                <Typography variant="font_12_bold">Options: </Typography>
                <br />
                {options?.map((option) => {
                  const chosenOption = option?.chosenOption;
                  const correct = option?.correct;

                  return (
                    <Box
                      className="question-options"
                      key={option.id}
                      sx={{
                        backgroundColor:
                          chosenOption && !correct
                            ? "#fc9b9b"
                            : correct
                            ? "#b2eaca"
                            : "",
                      }}
                    >
                      <Typography variant="font_12">
                        {option?.optionText}
                      </Typography>
                    </Box>
                  );
                })}
              </>
            )}
            {isTypeInterview && (
              <>
                <Typography variant="font_12_bold">Feedback:</Typography>{" "}
                {question?.interviewerFeedback}
              </>
            )}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TopicRelatedQuestionsListCard;
