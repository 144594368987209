import React, { useState } from "react";
import "./performanceCard.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import IconButtons from "../IconButtons/IconButtons";
import { down_arrow_icon, up_arrow_icon } from "../../constants/icons";
import { Box } from "@mui/system";
import {
  evaluationStatusChip,
  font_13,
  scoreChip
} from "./../../utils/utils";
import UserTableAvatar from "../UserTableAvatar/UserTableAvatar";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import { Link } from "react-router-dom";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import dayjs from "dayjs";

const PerformanceCard = ({ id, performance, asAttendee }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  const bootcampIncludesExercises = performance?.containsExercises;
  const bootcampIsScheduled = performance?.bootcamp?.status === "SCHEDULED";
  const bootcampIncludesAssessments =
    performance?.assessmentsMarking?.maximum > 0;
  const moduleAttendees = performance?.moduleAttendees;
  const status = evaluationStatusChip(performance?.bootcamp?.status);

  return (
    <Accordion
      className="performance-accordion"
      key={id}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        className="performance-accordion-summary"
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Table stickyHeader>
          <TableBody>
            <TableRow hover>
              {!asAttendee && (
                <TableCell
                  className="padding-left-1"
                  sx={{ minWidth: "14rem" }}
                >
                  <UserTableAvatar
                    imgAlt={performance?.attendee?.fullName}
                    imgSrc={performance?.attendee?.profilePictureUrl}
                    href={performance?.attendee?.employeeCode}
                    label={performance?.attendee?.fullName}
                    subLabel={performance?.attendee?.email}
                    deleted={performance?.attendee?.deleted}
                  />
                </TableCell>
              )}
              <TableCell
                className="padding-left-1"
                sx={{ minWidth: "18rem", maxWidth: "18rem" }}
              >
                <BootstrapTooltip title="View Bootcamp">
                  <Typography variant="font_12" color="secondary">
                    <Link
                      to={`/`}
                      state={{
                        propsToPass: {
                          bootcampName: performance?.bootcamp?.name,
                          bootcampId: performance?.bootcamp?.id,
                        },
                      }}
                    >
                      <u>{performance?.bootcamp?.name}</u>
                    </Link>
                  </Typography>
                </BootstrapTooltip>
              </TableCell>
              <TableCell className="padding-left-1" sx={{ minWidth: "21rem" }}>
                {performance?.batch?.module?.name}
                <Typography variant="font_12"></Typography>
              </TableCell>
              <TableCell className="padding-left-1" sx={{ minWidth: "6rem" }}>
                <Chip
                  className="same-size-chip"
                  label={status?.name}
                  variant={status?.theme}
                />
              </TableCell>
              <TableCell className="padding-left-1" sx={{ minWidth: "6rem" }}>
                <Typography variant="font_12">
                  {dayjs(performance?.bootcamp?.startDate)?.format(
                    "DD/MM/YYYY"
                  )}
                </Typography>
              </TableCell>
              <TableCell className="padding-left-1" sx={{ minWidth: "6rem" }}>
                <Typography variant="font_12">
                  {performance?.bootcamp?.endDate
                    ? dayjs(performance?.bootcamp?.endDate)?.format(
                        "DD/MM/YYYY"
                      )
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell
                className="padding-left-1"
                sx={{ minWidth: "13.625rem" }}
              >
                <UserTableAvatar
                  imgAlt={performance?.leadMentor?.fullName}
                  imgSrc={performance?.leadMentor?.profilePictureUrl}
                  href={performance?.leadMentor?.employeeCode}
                  label={performance?.leadMentor?.fullName}
                  subLabel={performance?.leadMentor?.email}
                  deleted={performance?.leadMentor?.deleted}
                />
              </TableCell>
              <TableCell
                className="padding-left-1"
                sx={{ minWidth: "13.625rem" }}
              >
                <UserTableAvatar
                  imgAlt={performance?.coMentor?.fullName}
                  imgSrc={performance?.coMentor?.profilePictureUrl}
                  href={performance?.coMentor?.employeeCode}
                  label={performance?.coMentor?.fullName}
                  subLabel={performance?.coMentor?.email}
                  deleted={performance?.coMentor?.deleted}
                />
              </TableCell>
              <TableCell align="center">
                <Box className="double-head-table-body">
                  <Typography
                    variant="font_13_bold"
                    className="width-10"
                    color="secondary"
                  >
                    <BootstrapTooltip
                      title={bootcampIncludesExercises && "Show Exercises"}
                    >
                      <Link
                        to={
                          bootcampIncludesExercises
                            ? asAttendee
                              ? `/exercises/attendee`
                              : `/exercises/reviewer`
                            : undefined
                        }
                        state={{
                          propsToPass: {
                            bootcamp: performance?.bootcamp,
                            ...(asAttendee
                              ? {}
                              : { submittedBy: performance?.attendee }),
                          },
                        }}
                        style={{
                          ...(!bootcampIncludesExercises && {
                            cursor: "text",
                          }),
                        }}
                      >
                        <Chip
                          className="score-chip"
                          label={`${performance?.exercisesMarking?.obtained}/${performance?.exercisesMarking?.maximum}`}
                          variant={scoreChip(
                            performance?.exercisesMarking?.obtained,
                            performance?.exercisesMarking?.maximum,
                            performance?.exercisesMarking?.percentage,
                            bootcampIsScheduled
                          )}
                        />
                      </Link>
                    </BootstrapTooltip>
                  </Typography>
                  <Typography
                    variant="font_13_bold"
                    className="width-10 text-cursor"
                    color="secondary"
                  >
                    <Chip
                      className="score-chip"
                      label={`${performance?.exercisesMarking?.percentage}`}
                      variant={scoreChip(
                        performance?.exercisesMarking?.obtained,
                        performance?.exercisesMarking?.maximum,
                        performance?.exercisesMarking?.percentage,
                        bootcampIsScheduled
                      )}
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="center">
                <Box className="double-head-table-body">
                  <Typography
                    variant="font_13_bold"
                    className="width-10 text-cursor"
                    color="secondary"
                  >
                    <BootstrapTooltip
                      title={bootcampIncludesAssessments && "Show Assessments"}
                    >
                      <Link
                        to={
                          bootcampIncludesAssessments
                            ? asAttendee
                              ? `/assessmentList/attendee`
                              : `/assessmentList/mentor`
                            : undefined
                        }
                        state={{
                          propsToPass: {
                            bootcamp: performance?.bootcamp,
                            ...(asAttendee
                              ? {}
                              : { submittedBy: performance?.attendee }),
                          },
                        }}
                        style={{
                          ...(!bootcampIncludesAssessments && {
                            cursor: "text",
                          }),
                        }}
                      >
                        <Chip
                          className="score-chip"
                          label={`${performance?.assessmentsMarking?.obtained}/${performance?.assessmentsMarking?.maximum}`}
                          variant={scoreChip(
                            performance?.assessmentsMarking?.obtained,
                            performance?.assessmentsMarking?.maximum,
                            performance?.assessmentsMarking?.percentage,
                            bootcampIsScheduled
                          )}
                        />
                      </Link>
                    </BootstrapTooltip>
                  </Typography>
                  <Typography
                    variant="font_13_bold"
                    className="width-10 text-cursor"
                    color="secondary"
                  >
                    <Chip
                      className="score-chip"
                      label={`${performance?.assessmentsMarking?.percentage}`}
                      variant={scoreChip(
                        performance?.assessmentsMarking?.obtained,
                        performance?.assessmentsMarking?.maximum,
                        performance?.assessmentsMarking?.percentage,
                        bootcampIsScheduled
                      )}
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="center">
                <Box className="double-head-table-body">
                  <Typography
                    variant="font_13_bold"
                    className="width-10 text-cursor"
                    color="secondary"
                  >
                    <Chip
                      className="score-chip"
                      label={`${performance?.totalMarking?.obtained}/${performance?.totalMarking?.maximum}`}
                      variant={scoreChip(
                        performance?.totalMarking?.obtained,
                        performance?.totalMarking?.maximum,
                        performance?.totalMarking?.percentage,
                        bootcampIsScheduled
                      )}
                    />
                  </Typography>
                  <Typography
                    variant="font_13_bold"
                    className="width-10 text-cursor"
                    color="secondary"
                  >
                    <Chip
                      className="score-chip"
                      label={`${performance?.totalMarking?.percentage}`}
                      variant={scoreChip(
                        performance?.totalMarking?.obtained,
                        performance?.totalMarking?.maximum,
                        performance?.totalMarking?.percentage,
                        bootcampIsScheduled
                      )}
                    />
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: "1.375rem" }}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <IconButtons
                      height={font_13}
                      width={font_13}
                      image={expanded ? up_arrow_icon : down_arrow_icon}
                    />
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionSummary>

      <AccordionDetails className="accordion-details">
        <TableContainer className="performance-table-container">
          <Table stickyHeader>
            <TableBody>
              {!moduleAttendees?.results?.length ? (
                <Grid container className="no-performance-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Modules Available"
                  />
                </Grid>
              ) : (
                moduleAttendees?.results?.map((attendee, index) => {
                  const moduleIncludesExercises = attendee?.containsExercises;
                  const moduleIncludesAssessments =
                    attendee?.assessmentsMarking?.maximum > 0;
                  const isModuleScheduled =
                    attendee?.batch?.module?.status === "SCHEDULED";
                  const status = evaluationStatusChip(
                    attendee?.batch?.module?.status
                  );
                  return (
                    <TableRow className="performance-row" hover key={index}>
                      {!asAttendee && (
                        <TableCell
                          className="padding-left-1"
                          sx={{ minWidth: "14rem" }}
                        >
                          <Typography variant="font_12"></Typography>
                        </TableCell>
                      )}
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "18rem", maxWidth: "18rem" }}
                      >
                        <Typography variant="font_12"></Typography>
                      </TableCell>
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "21rem" }}
                      >
                        <BootstrapTooltip title="View Module">
                          <Typography variant="font_12" color="secondary">
                            <Link
                              to={`/bootcamp/${performance?.bootcamp?.id}/module/${attendee?.batch?.module?.id}`}
                            >
                              <u>{attendee?.batch?.module?.name}</u>
                            </Link>
                          </Typography>
                        </BootstrapTooltip>
                      </TableCell>
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "6rem" }}
                      >
                        <Chip
                          className="same-size-chip"
                          label={status?.name}
                          variant={status?.theme}
                        />
                      </TableCell>
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "6rem" }}
                      >
                        <Typography variant="font_12">
                          {dayjs(attendee?.batch?.module?.startDate)?.format(
                            "DD/MM/YYYY"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "6rem" }}
                      >
                        <Typography variant="font_12">
                          {attendee?.batch?.module?.endDate
                            ? dayjs(attendee?.batch?.module?.endDate)?.format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "13.625rem" }}
                      >
                        <UserTableAvatar
                          imgAlt={attendee?.leadMentor?.fullName}
                          imgSrc={attendee?.leadMentor?.profilePictureUrl}
                          href={attendee?.leadMentor?.employeeCode}
                          label={attendee?.leadMentor?.fullName}
                          subLabel={attendee?.leadMentor?.email}
                          deleted={attendee?.leadMentor?.deleted}
                        />
                      </TableCell>
                      <TableCell
                        className="padding-left-1"
                        sx={{ minWidth: "13.625rem" }}
                      >
                        <UserTableAvatar
                          imgAlt={attendee?.coMentor?.fullName}
                          imgSrc={attendee?.coMentor?.profilePictureUrl}
                          href={attendee?.coMentor?.employeeCode}
                          label={attendee?.coMentor?.fullName}
                          subLabel={attendee?.coMentor?.email}
                          deleted={attendee?.coMentor?.deleted}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Box className="double-head-table-body">
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                            color="secondary"
                          >
                            <BootstrapTooltip
                              title={
                                moduleIncludesExercises && "Show Exercises"
                              }
                            >
                              <Link
                                to={
                                  moduleIncludesExercises
                                    ? asAttendee
                                      ? `/exercises/attendee`
                                      : `/exercises/reviewer`
                                    : undefined
                                }
                                state={{
                                  propsToPass: {
                                    bootcamp: performance?.bootcamp,
                                    module: attendee?.batch?.module,
                                    ...(asAttendee
                                      ? {}
                                      : { submittedBy: performance?.attendee }),
                                  },
                                }}
                                style={{
                                  ...(!moduleIncludesExercises && {
                                    cursor: "text",
                                  }),
                                }}
                              >
                                <Chip
                                  className="score-chip"
                                  label={`${attendee?.exercisesMarking?.obtained}/${attendee?.exercisesMarking?.maximum}`}
                                  variant={scoreChip(
                                    attendee?.exercisesMarking?.obtained,
                                    attendee?.exercisesMarking?.maximum,
                                    attendee?.exercisesMarking?.percentage,
                                    isModuleScheduled
                                  )}
                                />
                              </Link>
                            </BootstrapTooltip>
                          </Typography>
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                            color="secondary"
                          >
                            <Chip
                              className="score-chip"
                              label={`${attendee?.exercisesMarking?.percentage}`}
                              variant={scoreChip(
                                attendee?.exercisesMarking?.obtained,
                                attendee?.exercisesMarking?.maximum,
                                attendee?.exercisesMarking?.percentage,
                                isModuleScheduled
                              )}
                            />
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box className="double-head-table-body">
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                            color="secondary"
                          >
                            <BootstrapTooltip
                              title={
                                moduleIncludesAssessments && "Show Assessments"
                              }
                            >
                              <Link
                                to={
                                  moduleIncludesAssessments
                                    ? asAttendee
                                      ? `/assessmentList/attendee`
                                      : `/assessmentList/mentor`
                                    : undefined
                                }
                                state={{
                                  propsToPass: {
                                    bootcamp: performance?.bootcamp,
                                    module: attendee?.batch?.module,
                                    ...(asAttendee
                                      ? {}
                                      : { submittedBy: performance?.attendee }),
                                  },
                                }}
                                style={{
                                  ...(!moduleIncludesAssessments && {
                                    cursor: "text",
                                  }),
                                }}
                              >
                                <Chip
                                  className="score-chip"
                                  label={`${attendee?.assessmentsMarking?.obtained}/${attendee?.assessmentsMarking?.maximum}`}
                                  variant={scoreChip(
                                    attendee?.assessmentsMarking?.obtained,
                                    attendee?.assessmentsMarking?.maximum,
                                    attendee?.assessmentsMarking?.percentage,
                                    isModuleScheduled
                                  )}
                                />
                              </Link>
                            </BootstrapTooltip>
                          </Typography>
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                            color="secondary"
                          >
                            <Chip
                              className="score-chip"
                              label={`${attendee?.assessmentsMarking?.percentage}`}
                              variant={scoreChip(
                                attendee?.assessmentsMarking?.obtained,
                                attendee?.assessmentsMarking?.maximum,
                                attendee?.assessmentsMarking?.percentage,
                                isModuleScheduled
                              )}
                            />
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box className="double-head-table-body">
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                            color="secondary"
                          >
                            <Chip
                              className="score-chip"
                              label={`${attendee?.totalMarking?.obtained}/${attendee?.totalMarking?.maximum}`}
                              variant={scoreChip(
                                attendee?.totalMarking?.obtained,
                                attendee?.totalMarking?.maximum,
                                attendee?.totalMarking?.percentage,
                                isModuleScheduled
                              )}
                            />
                          </Typography>
                          <Typography
                            variant="font_13_bold"
                            className="width-10"
                            color="secondary"
                          >
                            <Chip
                              className="score-chip"
                              label={`${attendee?.totalMarking?.percentage}`}
                              variant={scoreChip(
                                attendee?.totalMarking?.obtained,
                                attendee?.totalMarking?.maximum,
                                attendee?.totalMarking?.percentage,
                                isModuleScheduled
                              )}
                            />
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ minWidth: "1.375rem" }} />
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default PerformanceCard;
